import React, { Component } from "react";
import { StoreState } from "../../reducers";
import {
  Auth,
  FC_Logout,
  FC_ReloadUserInfo,
  FC_ResendAccountVerificationCode,
  FC_SubmitAccountVerification,
} from "../../actions";
import { connect } from "react-redux";
import { ImMobile } from "react-icons/im";
import { BsCheckCircle } from "react-icons/bs";
import { BiReset } from "react-icons/bi";
import { Alert } from "../Alert/Alert";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import LoaderComponent from "../LoaderComponent/LoaderComponent";
import { IoArrowBackOutline } from "react-icons/io5";

interface AccountVerificationProps {
  auth: Auth;
  FC_SubmitAccountVerification: (
    data: {
      user_id: string;
      verification_code: number;
    },
    callback: (
      loading: boolean,
      res: { type: "success" | "error"; msg: string } | null
    ) => void
  ) => void;
  FC_ResendAccountVerificationCode: (
    user_id: string,
    callback: (
      loading: boolean,
      res: { type: "success" | "error"; msg: string } | null
    ) => void
  ) => void;
  FC_ReloadUserInfo: (callBack: (loading: boolean) => void) => void;
  FC_Logout: () => void;
}
interface AccountVerificationState {
  loading: boolean;
  error: {
    type: "main";
    msg: string;
  } | null;
  success: string;
  verificationCode: string;
  reloadingAccount: boolean;
  resendingCode: boolean;
}

class _AccountVerification extends Component<
  AccountVerificationProps,
  AccountVerificationState
> {
  constructor(props: AccountVerificationProps) {
    super(props);
    this.state = {
      loading: false,
      success: "",
      error: null,
      verificationCode: "",
      reloadingAccount: false,
      resendingCode: false,
    };
  }
  verifyCode = () => {
    if (this.state.verificationCode === "") {
      return this.setState({
        error: {
          type: "main",
          msg: "Please type verification code sent from your mobile phone",
        },
      });
    }
    if (isNaN(parseInt(this.state.verificationCode))) {
      return this.setState({
        error: {
          type: "main",
          msg: "Please type numbers only",
        },
      });
    }
    if (this.props.auth.user !== null) {
      this.setState({ loading: true });
      this.props.FC_SubmitAccountVerification(
        {
          user_id: this.props.auth.user.user_id,
          verification_code: parseInt(this.state.verificationCode),
        },
        (
          loading: boolean,
          res: { type: "success" | "error"; msg: string } | null
        ) => {
          this.setState({ loading: loading });
          if (res?.type === "success") {
            this.setState({ loading: false, success: res.msg, error: null });
            setTimeout(() => {
              this.reloadAccountDetails();
            }, 1000);
          }
          if (res?.type === "error") {
            this.setState({
              loading: false,
              success: "",
              error: { type: "main", msg: res.msg },
            });
          }
        }
      );
    }
  };
  resendCode = () => {
    if (this.props.auth.user !== null) {
      this.setState({ loading: true, resendingCode: true });
      this.props.FC_ResendAccountVerificationCode(
        this.props.auth.user.user_id,
        (
          loading: boolean,
          res: { type: "success" | "error"; msg: string } | null
        ) => {
          this.setState({ loading: loading });
          if (res?.type === "success") {
            this.setState({
              loading: false,
              error: null,
              success: res.msg,
              resendingCode: true,
            });
          }
          if (res?.type === "error") {
            this.setState({
              loading: false,
              error: { type: "main", msg: res.msg },
              success: "",
            });
          }
        }
      );
    }
  };
  reloadAccountDetails = () => {
    this.setState({ reloadingAccount: true });
    this.props.FC_ReloadUserInfo((loading: boolean) =>
      this.setState({ reloadingAccount: loading })
    );
  };
  render() {
    if (this.state.reloadingAccount === true) {
      return (
        <div>
          <div className="">
            <LoaderComponent />
          </div>
        </div>
      );
    }
    return (
      <div>
        <div className="p-8 relative">
          <div className="flex flex-row items-center justify-center">
            <div className="flex items-center justify-center h-32 w-32 border-4 rounded-full border-yellow-500">
              <ImMobile className="text-7xl animate__animated animate__flash animate__infinite animate__slower" />
            </div>
          </div>
          <div className="flex flex-col items-center justify-center pt-3 text-center">
            <div className="font-bold text-2xl uppercase">
              Verify your Account
            </div>
            <div className="text-gray-500 text-sm">
              In order to start using your WIDUIT account, you need to verify
              code sent to your phone number messages
            </div>
          </div>
          {this.state.success === "" && this.state.resendingCode === false && (
            <div className="flex flex-row items-center justify-center gap-2 w-full pt-5">
              <div
                onClick={() =>
                  this.state.loading === false && this.resendCode()
                }
                className="flex flex-row items-center justify-center gap-2 px-3 py-2 rounded-md bg-gray-100 hover:bg-gray-500 hover:text-white cursor-pointer"
              >
                <div>
                  <BiReset className="text-2xl" />
                </div>
                <span className="truncate">Resend</span>
              </div>
              <input
                type="text"
                className={`border border-green-500 px-3 py-2 rounded-md w-full font-bold focus-within:outline-none`}
                placeholder="Code: x x x x x"
                disabled={this.state.loading}
                value={this.state.verificationCode}
                onChange={(e) =>
                  this.setState({
                    verificationCode: e.target.value,
                    error: null,
                    success: "",
                  })
                }
                autoFocus={true}
              />
              <div
                className={`flex flex-row items-center justify-center gap-2 px-3 py-2 rounded-md ${
                  this.state.loading === true
                    ? "bg-gray-500 cursor-not-allowed"
                    : "bg-green-600 hover:bg-green-700 cursor-pointer"
                } text-white`}
                onClick={() =>
                  this.state.loading === false && this.verifyCode()
                }
              >
                <div>
                  {this.state.loading === true ? (
                    <AiOutlineLoading3Quarters className="text-xl animate-spin" />
                  ) : (
                    <BsCheckCircle className="text-xl" />
                  )}
                </div>
                {this.state.loading === true ? (
                  <span className="truncate animate__animated animate__fadeIn animate__infinite">
                    Verifying Code...
                  </span>
                ) : (
                  <span className="truncate">Verify Account</span>
                )}
              </div>
            </div>
          )}
          {this.state.loading === true && this.state.resendingCode === true && (
            <div className="flex flex-row items-center justify-center w-full pt-3">
              <div className="flex flex-row items-center justify-center bg-yellow-50 px-3 py-2 rounded-lg w-max font-bold gap-2">
                <div>
                  <AiOutlineLoading3Quarters className="text-3xl animate-spin text-yellow-500" />
                </div>
                <span className="truncate animate__animated animate__fadeIn animate__infinite text-yellow-800 text-sm">
                  Resending Account Verification Code...
                </span>
              </div>
            </div>
          )}
          {this.state.error !== null && (
            <div className="pt-3">
              <Alert
                title={this.state.error.msg}
                type={"danger"}
                onClose={() => this.setState({ error: null, success: "" })}
              />
            </div>
          )}
          {this.state.success !== "" && (
            <div className="pt-3">
              <Alert
                title={this.state.success}
                type={"success"}
                onClose={() =>
                  this.state.resendingCode === true &&
                  this.setState({
                    error: null,
                    success: "",
                    resendingCode: false,
                  })
                }
                allow_time_out={this.state.resendingCode}
              />
            </div>
          )}
          <div
            title="Click to logout"
            className="rounded-full flex items-center justify-center h-10 w-10 absolute top-3 left-3 bg-gray-100 cursor-pointer hover:bg-primary-100 hover:text-primary-900 border border-gray-400 hover:border-primary-200"
            onClick={() => this.props.FC_Logout()}
          >
            <IoArrowBackOutline className="text-2xl" />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  auth,
}: StoreState): {
  auth: Auth;
} => {
  return {
    auth,
  };
};

export const AccountVerification = connect(mapStateToProps, {
  FC_SubmitAccountVerification,
  FC_ResendAccountVerificationCode,
  FC_ReloadUserInfo,
  FC_Logout,
})(_AccountVerification);
