import React from "react";
import MainContainer from "../MainContainer/MainContainer";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const LoaderComponent = () => {
  return (
    <MainContainer className="mt-3 p-6 text-center">
      <div className="mt-5"></div>
      <div className="flex items-center justify-center">
        <AiOutlineLoading3Quarters className="text-7xl text-yellow-500 animate-spin" />
      </div>
      <div className="px-3 mt-2 mb-4 animate__animated animate__fadeIn animate__infinite">
        Loading, please wait...
      </div>
    </MainContainer>
  );
};

export default LoaderComponent;
