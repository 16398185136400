import {
  CleanUserDetails,
  LoginSuccessDetails,
  LogoutUser,
} from "./auth.action";
import { GetPostsListAction, GetUserPostsListAction } from "./post.action";
import {
  GetSystemInfoAction,
  SetShowNavigationStatusAction,
  SetSystemMessageAction,
} from "./system.action";

export enum ActionTypes {
  LOGIN_DETAILS = "LOGIN_DETAILS",
  USER_LOGIN_SUCCESS_DATA = "USER_LOGIN_SUCCESS_DATA",
  CLEAN_USER_DETAILS = "CLEAN_USER_DETAILS",
  LOGOUT = "LOGOUT",
  GET_SYSTEM_INFO = "GET_SYSTEM_INFO",
  SET_SYSTEM_ERROR_MESSAGE = "SET_SYSTEM_ERROR_MESSAGE",
  SET_SHOW_NAVIGATION = "SET_SHOW_NAVIGATION",
  GET_POSTS_LIST = "GET_POSTS_LIST",
  GET_USER_POSTS_LIST = "GET_USER_POSTS_LIST",
  CREATE_NEW_POST = "CREATE_NEW_POST",
}

export type Action =
  | CleanUserDetails
  | LoginSuccessDetails
  | LogoutUser
  | GetSystemInfoAction
  | SetSystemMessageAction
  | SetShowNavigationStatusAction
  | GetPostsListAction
  | GetUserPostsListAction;
