import axios from "axios";
import { Dispatch } from "redux";
import { ActionTypes } from "./types";
import { API_URL } from "../utils/api";
import { setAxiosToken } from "../utils/AxiosToken";
import {
  BooleanEnum,
  PostReplyEnum,
  PostTypeEnum,
  PostValidationEnum,
  SetSystemMessageAction,
} from "./system.action";
import { errorToText } from "../utils/functions";
import { CreatePostType } from "../components/ForumComponents/CreatePostSection";
import { GenderValueEnum } from "./auth.action";

/**
 * * ****************************** INTERFACES *****************************
 */

export interface GetReplyInterface {
  post_reply_id: string;
  post_id: string;
  reply_message: string;
  reply_validation_status: PostReplyEnum;
  reply_validated_at: string | null;
  created_at: string;
  user_id: string;
  full_name: string;
  phone_number: string;
  email: string;
  country: string | null;
  gender: string | null;
  dob: string | null;
  region: string | null;
}

export interface PostInterface {
  post_id: string;
  topic_id: string;
  post_type: PostTypeEnum;
  post_title: string;
  media_file_name: string | null;
  media_type: CreatePostType;
  post_validation_status: PostValidationEnum;
  post_validated_at: string | null;
  post_validated_by: string | null;
  created_at: string;
  created_by: string;
  updated_at: string | null;
  updated_by: string | null;
  is_post_deleted: BooleanEnum;
  deleted_at: string | null;
  deleted_by: string | null;
  replies: GetReplyInterface[]; //To be clarified
  topic_name: string;
  full_name: string;
  phone_number: string;
  email: string;
  country: string | null;
  gender: GenderValueEnum | null;
  dob: string | null;
  region: string | null;
}

export interface ReferralUserInterface {
  referral_id: string;
  post_id: string;
  service_provider_id: string;
  beneficiary_id: string;
  full_name: string;
  phone_number: string;
  email: string;
  country: string | null;
  gender: string | null;
  dob: string | null;
  region: string | null;
}

export interface ReferralDetailsInterface {
  referral_id: string;
  post_id: string;
  topic_id: string;
  topic_name: string;
  post_type: PostTypeEnum;
  post_title: string;
  media_file_name: string | null;
  media_type: CreatePostType;
  service_provider_id: string;
  beneficiary_id: string;
  created_at: string;
  full_name: string;
  phone_number: string;
  email: string;
  country: string | null;
  gender: GenderValueEnum | null;
  dob: string | null;
  region: string | null;
  replies: GetReplyInterface[];
}

export interface PostTestimonialInterface {
  testimonial_id: string;
  post_id: string;
  service_provider_id: string;
  beneficiary_id: string;
  testimonial: string;
  full_name: string;
  phone_number: string;
  email: string;
  country: string | null;
  gender: string | null;
  dob: string | null;
  region: string | null;
}

export interface PostStore {
  posts: PostInterface[] | null;
  userPosts: PostInterface[] | null;
}

//* ********************** ACTION TYPE INTERCACES ********************** */
export interface GetPostsListAction {
  type: ActionTypes.GET_POSTS_LIST;
  payload: PostInterface[];
}

export interface GetUserPostsListAction {
  type: ActionTypes.GET_USER_POSTS_LIST;
  payload: PostInterface[];
}

/**
 * * ****************************** ACTIONS *****************************
 */
/**
 * @description Register the account to the api
 * @param account
 * @param MsgHandler return the error from the API
 * @returns
 */
export const FC_GetPostsListByValidationStatus = (
  validation_status: PostValidationEnum,
  callback: (
    loading: boolean,
    res: {
      type: "success" | "error";
      msg: string;
      data: PostInterface[];
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.get<PostInterface[]>(
        `${API_URL}/posts/status/${validation_status}`
      );
      console.log({ posts_by_validation_status: res.data });
      callback(false, {
        data: res.data,
        msg: "",
        type: "success",
      });
    } catch (error: any) {
      callback(false, {
        data: [],
        msg: errorToText(error),
        type: "error",
      });
      dispatch<SetSystemMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: {
          success: "",
          error: errorToText(error),
        },
      });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_GetPostsListByPostType = (
  post_type: PostTypeEnum,
  callback: (
    loading: boolean,
    res: {
      type: "success" | "error";
      msg: string;
      data: PostInterface[];
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.get<PostInterface[]>(
        `${API_URL}/posts/type/${post_type}`
      );
      console.log({ posts_by_post_type: res.data });
      callback(false, {
        data: res.data.sort((a, b) =>
          new Date(a.created_at).getTime() > new Date(b.created_at).getTime()
            ? -1
            : 1
        ),
        msg: "",
        type: "success",
      });
    } catch (error: any) {
      callback(false, {
        data: [],
        msg: errorToText(error),
        type: "error",
      });
      dispatch<SetSystemMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: {
          success: "",
          error: errorToText(error),
        },
      });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_GetUserPostsList = (
  user_id: string,
  callback: (
    loading: boolean,
    res: {
      type: "success" | "error";
      msg: string;
      data: PostInterface[];
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.get<PostInterface[]>(
        `${API_URL}/posts/user/${user_id}`
      );
      console.log({ posts_by_post_type: res.data });
      callback(false, {
        data: res.data.sort((a, b) =>
          new Date(a.created_at).getTime() > new Date(b.created_at).getTime()
            ? -1
            : 1
        ),
        msg: "",
        type: "success",
      });
    } catch (error: any) {
      callback(false, {
        data: [],
        msg: errorToText(error),
        type: "error",
      });
      dispatch<SetSystemMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: {
          success: "",
          error: errorToText(error),
        },
      });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_CreateNewPost = (
  data: {
    topic_id: string;
    post_type: PostTypeEnum;
    post_title: string;
    media_type: CreatePostType;
    media_file_name: File | null;
  },
  callback: (
    loading: boolean,
    res: { type: "success" | "error"; msg: string } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    const formData = new FormData();
    formData.append("topic_id", data.topic_id);
    formData.append("post_type", data.post_type);
    formData.append("post_title", data.post_title);
    formData.append("media_type", data.media_type);
    data.media_file_name !== null &&
      formData.append("media_file_name", data.media_file_name);
    try {
      await axios.post(`${API_URL}/posts`, formData);
      callback(false, { type: "success", msg: "Post created successfully!" });
    } catch (error: any) {
      callback(false, { type: "error", msg: errorToText(error) });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_SubmitPostReply = (
  data: {
    post_id: string;
    user_id: string;
    reply_message: string;
  },
  callback: (
    loading: boolean,
    res: {
      type: "success" | "error";
      msg: string;
      reply: GetReplyInterface | null;
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.post<{
        message: string;
        data: GetReplyInterface;
      }>(`${API_URL}/replies`, data);
      console.log({ replied: res.data });
      callback(false, {
        type: "success",
        msg: "Reply submitted successfully!",
        reply: res.data.data,
      });
    } catch (error: any) {
      callback(false, { type: "error", msg: errorToText(error), reply: null });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_SubmitPostValidationStatus = (
  data: {
    post_id: string;
    post_validation_status: PostValidationEnum;
  },
  callback: (
    loading: boolean,
    res: { type: "success" | "error"; msg: string } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      await axios.patch(`${API_URL}/posts/validation`, data);
      callback(false, {
        type: "success",
        msg: "Post status updated successfully!",
      });
    } catch (error: any) {
      callback(false, { type: "error", msg: errorToText(error) });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_ChangeReplyStatus = (
  data: {
    post_reply_id: string;
    reply_validation_status: PostReplyEnum;
  },
  callback: (
    loading: boolean,
    res: { type: "success" | "error"; msg: string } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      await axios.patch(`${API_URL}/replies/validation`, data);
      callback(false, {
        type: "success",
        msg: "Post reply status updated successfully!",
      });
      dispatch<SetSystemMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: {
          success: "Post reply status updated successfully!",
          error: "",
        },
      });
    } catch (error: any) {
      dispatch<SetSystemMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: {
          success: "",
          error: errorToText(error),
        },
      });
      callback(false, { type: "error", msg: errorToText(error) });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_GetReferralByPost = (
  post_id: string,
  callback: (
    loading: boolean,
    res: {
      type: "success" | "error";
      msg: string;
      data: ReferralUserInterface[];
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.get<ReferralUserInterface[]>(
        `${API_URL}/referrals/post/${post_id}`
      );
      console.log({ ReferralUser: res.data });
      callback(false, {
        data: res.data,
        msg: "",
        type: "success",
      });
    } catch (error: any) {
      callback(false, {
        data: [],
        msg: errorToText(error),
        type: "error",
      });
      dispatch<SetSystemMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: {
          success: "",
          error: errorToText(error),
        },
      });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_GetReferralByProvider = (
  service_provider_id: string,
  callback: (
    loading: boolean,
    res: {
      type: "success" | "error";
      msg: string;
      data: ReferralDetailsInterface[];
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.get<ReferralDetailsInterface[]>(
        `${API_URL}/referrals/provider/${service_provider_id}`
      );
      console.log({ ReferralDetails: res.data });
      callback(false, {
        data: res.data,
        msg: "",
        type: "success",
      });
    } catch (error: any) {
      callback(false, {
        data: [],
        msg: errorToText(error),
        type: "error",
      });
      dispatch<SetSystemMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: {
          success: "",
          error: errorToText(error),
        },
      });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_AddReferralUser = (
  data: {
    post_id: string;
    service_provider_id: string;
    beneficiary_id: string;
  },
  callback: (
    loading: boolean,
    res: {
      type: "success" | "error";
      msg: string;
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.post(`${API_URL}/referrals`, data);
      console.log({ AddReferral: res.data });
      callback(false, {
        msg: "",
        type: "success",
      });
    } catch (error: any) {
      callback(false, {
        msg: errorToText(error),
        type: "error",
      });
      dispatch<SetSystemMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: {
          success: "",
          error: errorToText(error),
        },
      });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_PostTestimonials = (
  post_id: string,
  callback: (
    loading: boolean,
    res: {
      type: "success" | "error";
      msg: string;
      data: PostTestimonialInterface[];
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.get<PostTestimonialInterface[]>(
        `${API_URL}/testimonials/post/${post_id}`
      );
      console.log({ PostTestimonials: res.data });
      callback(false, {
        data: res.data,
        msg: "",
        type: "success",
      });
    } catch (error: any) {
      callback(false, {
        data: [],
        msg: errorToText(error),
        type: "error",
      });
      dispatch<SetSystemMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: {
          success: "",
          error: errorToText(error),
        },
      });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_AddPostTestimonials = (
  data: {
    post_id: string;
    service_provider_id: string;
    beneficiary_id: string;
    testimonial: string;
  },
  callback: (
    loading: boolean,
    res: {
      type: "success" | "error";
      msg: string;
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.post(`${API_URL}/testimonials`, data);
      console.log({ AddPostTestimonials: res.data });
      callback(false, {
        msg: "Post Testimonial added successfully!",
        type: "success",
      });
    } catch (error: any) {
      callback(false, {
        msg: errorToText(error),
        type: "error",
      });
      dispatch<SetSystemMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: {
          success: "",
          error: errorToText(error),
        },
      });
      console.log("err: ", { ...error });
    }
  };
};

export interface UserDashboardDataInterface {
  total_approved: number;
  total_not_rejected: number;
  total_pending: number;
  total_post: number;
  total_private: number;
  total_public: number;
  total_reply: number;
}

export const FC_GetUserDashboardSummary = (
  user_id: string,
  callback: (
    loading: boolean,
    res: {
      type: "success" | "error";
      msg: string;
      data: UserDashboardDataInterface | null;
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.get<UserDashboardDataInterface>(
        `${API_URL}/dashboard/user/${user_id}`
      );
      console.log({ user_dashboard: res.data });
      callback(false, {
        data: res.data,
        msg: "",
        type: "success",
      });
    } catch (error: any) {
      callback(false, {
        data: null,
        msg: errorToText(error),
        type: "error",
      });
      dispatch<SetSystemMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: {
          success: "",
          error: errorToText(error),
        },
      });
      console.log("err: ", { ...error });
    }
  };
};
