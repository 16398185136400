import React, { Component } from "react";
import { AiOutlineLogout } from "react-icons/ai";
import { FaUserCircle } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { Auth, UserType } from "../../actions";
import { RiLockPasswordLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { CgMenuGridO } from "react-icons/cg";
import { MdDashboard } from "react-icons/md";

interface NavBarProps {
  auth: Auth;
  FC_Logout: () => void;
  showSideNav: boolean;
  setShowSideNav: (showSideNav: boolean) => void;
  CheckIfDoingExam: boolean;
}
interface NavBarState {
  view_user: boolean;
  loading: boolean;
}

export class NavBar extends Component<NavBarProps, NavBarState> {
  constructor(props: NavBarProps) {
    super(props);

    this.state = {
      loading: false,
      view_user: false,
    };
  }
  componentDidMount(): void {}
  render() {
    return (
      <div>
        <div
          className="bg-primary-800 py-1 pl-3 fixed top-0 right-0 left-0 shadow-xl"
          style={{ zIndex: 9 }}
        >
          <div className="flex flex-row items-center justify-between gap-3">
            <div className="flex flex-row items-center gap-2">
              {this.props.showSideNav === false &&
                this.props.CheckIfDoingExam === false &&
                this.props.auth.user?.user_type !== UserType.BENEFICIARY && (
                  <div>
                    <div
                      onClick={() =>
                        this.props.setShowSideNav(!this.props.showSideNav)
                      }
                      className="bg-primary-700 h-10 w-10 rounded-full flex items-center justify-center cursor-pointer animate__animated animate__bounceIn"
                    >
                      {<CgMenuGridO className="text-3xl text-white" />}
                    </div>
                  </div>
                )}
              <div
                className="my-1"
                style={{ paddingBottom: "2px", paddingTop: "2px" }}
              >
                <MdDashboard className="text-white text-4xl" />
              </div>
              <div className="hidden text-white font-extrabold text-lg md:flex flex-row items-center gap-1">
                <div className="">
                  {this.props.auth.user?.user_type === UserType.SERVICE_PROVIDER
                    ? "Service Provider Panel"
                    : "Administrator Panel"}
                </div>
              </div>
            </div>
            {this.props.CheckIfDoingExam === false && (
              <div className="flex flex-row items-center gap-2 justify-end">
                {/* User icon */}
                <div className="relative">
                  <div
                    onClick={() =>
                      this.setState({ view_user: !this.state.view_user })
                    }
                    className="bg-primary-700 rounded-full flex items-center justify-center hover:bg-primary-900 h-10 w-10 mr-2 text-white  cursor-pointer"
                  >
                    {this.state.view_user === false ? (
                      <FaUserCircle className="text-4xl animate__animated animate__fadeIn" />
                    ) : (
                      <IoMdClose className="text-3xl animate__animated animate__fadeIn" />
                    )}
                  </div>
                  {this.state.view_user === true && (
                    <div>
                      <div
                        className="fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-60"
                        onClick={() => this.setState({ view_user: false })}
                      ></div>
                      <div className="absolute right-3 top-1">
                        <div className="bg-white p-3 rounded-lg w-64 shadow-xl animate__animated animate__slideInRight animate__faster">
                          <div className="flex flex-col items-center justify-center w-full gap-0">
                            <div className="mt-3">
                              <div className="rounded-full text-gray-400 flex items-center justify-center h-24 w-24 overflow-hidden">
                                <FaUserCircle className="text-8xl" />
                              </div>
                            </div>
                            <div className="font-light mt-3 text-center text-base text-gray-500 w-full">
                              <span>{this.props.auth.user?.full_name}</span>
                            </div>
                            <div className="font-bold text-sm text-center pb-2 text-black w-full">
                              <span>{this.props.auth.user?.phone_number}</span>
                            </div>
                          </div>

                          <div className="mt-5">
                            <div className="text-sm text-gray-600 mt-5">
                              Action menu
                            </div>
                            <Link
                              onClick={() =>
                                this.setState({ view_user: false })
                              }
                              to={"/profile"}
                              className="flex flex-row items-center gap-2 bg-gray-200 rounded-md px-2 py-1 cursor-pointer hover:bg-primary-800 hover:text-white group mb-2"
                            >
                              <div>
                                <FaUserCircle className="text-xl text-gray-500 group-hover:text-white" />
                              </div>
                              <div>User Profile</div>
                            </Link>
                            <Link
                              onClick={() =>
                                this.setState({ view_user: false })
                              }
                              to={"/change-password"}
                              className="flex flex-row items-center gap-2 bg-gray-200 rounded-md px-2 py-1 cursor-pointer hover:bg-primary-800 hover:text-white group"
                            >
                              <div>
                                <RiLockPasswordLine className="text-xl text-gray-500 group-hover:text-white" />
                              </div>
                              <div>Change password</div>
                            </Link>
                            <div
                              onClick={() => {
                                this.setState({ view_user: false });
                                this.props.FC_Logout();
                              }}
                              className="flex flex-row items-center gap-2 border border-yellow-700 rounded-md px-2 py-1 cursor-pointer hover:bg-yellow-700 hover:text-white group mt-2"
                            >
                              <div>
                                <AiOutlineLogout className="text-xl text-gray-500 group-hover:text-white" />
                              </div>
                              <div>Sign out</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
