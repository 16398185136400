import axios from "axios";
import { Dispatch } from "redux";
import { ActionTypes } from "./types";
import { API_URL } from "../utils/api";
import { setAxiosToken } from "../utils/AxiosToken";
import { errorToText } from "../utils/functions";

/**
 * * ****************************** INTERFACES *****************************
 */

export enum BooleanEnum {
  TRUE = "TRUE",
  FALSE = "FALSE",
}
export enum AccountVerificationEnum {
  NOT_VERIFIED = "NOT_VERIFIED",
  VERIFIED = "VERIFIED",
}
export enum AccessStatusEnum {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  BLOCKED = "BLOCKED",
}
export enum PostTypeEnum {
  PUBLIC = "PUBLIC",
  PRIVATE = "PRIVATE",
}
export enum PostValidationEnum {
  PENDING = "PENDING",
  REJECTED = "REJECTED",
  APPROVED = "APPROVED",
}
export enum PostReplyEnum {
  CANCELED = "CANCELED",
  ACCEPTED = "ACCEPTED",
}

export enum UserType {
  ADMIN = "ADMIN",
  BENEFICIARY = "BENEFICIARY",
  SERVICE_PROVIDER = "SERVICE_PROVIDER",
}

export interface SharedDocument {
  shared_document_id: string;
  document_title: string;
  document_description: string;
  shared_file_name: string;
  user_id: string;
  full_name: string;
  phone_number: string;
  email: string;
  country: string | null;
  gender: string | null;
  dob: string | null;
  region: string | null;
}

export interface TopicItemInterface {
  topic_id: string;
  topic_name: string;
}
export interface QualificationItemInterface {
  qualification_id: string;
  qualification_name: string;
}
export interface EducationFieldItemInterface {
  education_field_id: string;
  education_field_name: string;
}

export interface BasicInfo {
  topic: TopicItemInterface[];
  qualification: QualificationItemInterface[];
  education_field: EducationFieldItemInterface[];
}

export interface System {
  side_nav: boolean;
  basic_info: BasicInfo | null;
  message: {
    error: string;
    success: string;
  };
  showNavigation: boolean;
}

export interface DashboardDetailsInterface {
  user_count: {
    total_users: number;
    total_admin: number;
    total_beneficiary: number;
    total_provider: number;
    total_not_verified: number;
    total_verified: number;
    total_active: number;
    total_inactive: number;
    total_blocked: number;
  }[];
  post_count: {
    total_post: number;
    total_public: number;
    total_private: number;
    total_pending: number;
    total_not_rejected: number;
    total_approved: number;
    total_referral: number;
    total_reply: number;
  }[];
  shared_doc_count: {
    total_shared: number;
    total_downloaded: number;
  }[];
}

//* ********************** ACTION TYPE INTERCACES ********************** */
export interface GetSystemInfoAction {
  type: ActionTypes.GET_SYSTEM_INFO;
  payload: BasicInfo;
}

export interface SetSystemMessageAction {
  type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE;
  payload: {
    success: string;
    error: string;
  };
}

export interface SetShowNavigationStatusAction {
  type: ActionTypes.SET_SHOW_NAVIGATION;
  payload: boolean;
}

/**
 * * ****************************** ACTIONS *****************************
 */
/**
 * @description Register the account to the api
 * @param account
 * @param MsgHandler return the error from the API
 * @returns
 */
export const FC_GetSystemInfo = (callback: (loading: boolean) => void) => {
  return async (dispatch: Dispatch) => {
    callback(true);
    setAxiosToken();
    try {
      const res = await axios.get<BasicInfo>(`${API_URL}/basic/data`);

      console.log({ system_basic_info: res.data });

      dispatch<GetSystemInfoAction>({
        type: ActionTypes.GET_SYSTEM_INFO,
        payload: res.data,
      });
      callback(false);
    } catch (error) {
      callback(false);
      console.log("err: ", { ...(error as any) });
    }
  };
};

export const FC_SetSystemMessage = (success: string, error: string) => {
  return async (dispatch: Dispatch) => {
    dispatch<SetSystemMessageAction>({
      type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
      payload: {
        success: success,
        error: success,
      },
    });
  };
};

export const FC_SetShowNavigationStatus = (status: boolean) => {
  return async (dispatch: Dispatch) => {
    dispatch<SetShowNavigationStatusAction>({
      type: ActionTypes.SET_SHOW_NAVIGATION,
      payload: status,
    });
  };
};

export const FC_GetSharedDocuments = (
  callback: (
    loading: boolean,
    res: {
      type: "success" | "error";
      msg: string;
      data: SharedDocument[];
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.get<SharedDocument[]>(`${API_URL}/shared`);

      console.log({ SHARED_DOCUMENTS: res.data });

      callback(false, {
        data: res.data,
        msg: "",
        type: "success",
      });
    } catch (error: any) {
      callback(false, {
        type: "error",
        msg: errorToText(error),
        data: [],
      });
      console.log("err: ", { ...(error as any) });
    }
  };
};

export const FC_SubmitSharedDocument = (
  data: {
    user_id: string;
    document_title: string;
    document_description: string;
    shared_file_name: File;
  },
  callback: (
    loading: boolean,
    res: {
      type: "success" | "error";
      msg: string;
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    const formData = new FormData();
    formData.append("user_id", data.user_id);
    formData.append("document_title", data.document_title);
    formData.append("document_description", data.document_description);
    formData.append("shared_file_name", data.shared_file_name);
    try {
      const res = await axios.post(`${API_URL}/shared`, formData);

      console.log({ SUBMIT_SHARED_DOCUMENT: res.data });

      callback(false, {
        msg: "Document Added Successfully!",
        type: "success",
      });
    } catch (error: any) {
      callback(false, {
        type: "error",
        msg: errorToText(error),
      });
      console.log("err: ", { ...(error as any) });
    }
  };
};

export const FC_DownloadDocument = (
  data: { shared_document_id: string; user_id: string },
  callback: (
    loading: boolean,
    res: {
      type: "success" | "error";
      msg: string;
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.post(`${API_URL}/downloaded`, data);

      console.log({ DOWNLOAD_DOCUMENTS: res.data });

      callback(false, {
        msg: "Document downloaded successfully!",
        type: "success",
      });
    } catch (error: any) {
      callback(false, {
        type: "error",
        msg: errorToText(error),
      });
      console.log("err: ", { ...(error as any) });
    }
  };
};

export const FC_GetDashboardDetails = (
  callback: (
    loading: boolean,
    res: {
      type: "success" | "error";
      msg: string;
      data: DashboardDetailsInterface;
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.get<DashboardDetailsInterface>(
        `${API_URL}/dashboard/general`
      );

      console.log({ DASHBOARD_DETAILS: res.data });

      callback(false, {
        data: res.data,
        msg: "",
        type: "success",
      });
    } catch (error: any) {
      callback(false, {
        type: "error",
        msg: errorToText(error),
        data: {
          post_count: [],
          shared_doc_count: [],
          user_count: [],
        },
      });
      console.log("err: ", { ...(error as any) });
    }
  };
};
