import axios from "axios";
import { Dispatch } from "redux";
import { ActionTypes } from "./types";
import { API_URL } from "../utils/api";
import { APP_TOKEN_NAME, setAxiosToken } from "../utils/AxiosToken";
import { errorToText } from "../utils/functions";
import {
  AccessStatusEnum,
  AccountVerificationEnum,
  SetSystemMessageAction,
  UserType,
} from "./system.action";

/**
 * * ****************************** INTERFACES *****************************
 */

export enum GenderValueEnum {
  MALE = "MALE",
  FEMALE = "FEMALE",
}

export interface API_GetUserDetails {
  user_id: string;
  full_name: string;
  phone_number: string;
  email: string;
  country: string | null;
  gender: GenderValueEnum | null;
  dob: string | null;
  region: string | null;
  user_type: UserType;
  qualification_id: string | null;
  qualification_name: string | null;
  education_field_id: string | null;
  education_field_name: string | null;
  access_status: AccessStatusEnum;
  verification: AccountVerificationEnum;
  verified_at: string;
  jwt: string;
}

export interface UserInterface {
  user_id: string;
  full_name: string;
  phone_number: string;
  email: string;
  country: string | null;
  gender: string | null;
  dob: string | null;
  region: string | null;
  user_type: UserType;
  qualification_id: string | null;
  qualification_name: string | null;
  education_field_id: string | null;
  education_field_name: string | null;
  access_status: AccessStatusEnum;
  verification: AccountVerificationEnum;
  verified_at: string | null;
}

export interface RegisterDataInterface {
  full_name: string;
  email: string;
  phone_number: string;
  password: string;
}

export interface UpdateProfileInfoInterface {
  user_id: string;
  full_name: string;
  email: string;
  phone_number: string;
  country: string;
  region: string;
  occupation_id: UserType;
  gender: GenderValueEnum;
  dob: string;
}

export interface Auth {
  loading: boolean;
  isAuthenticated: boolean;
  token: string;
  user: API_GetUserDetails | null;
}

//* ********************** ACTION TYPE INTERCACES ********************** */
export interface FetchLoginDetails {
  type: ActionTypes.LOGIN_DETAILS;
  payload: Auth;
}

export interface LoginSuccessDetails {
  type: ActionTypes.USER_LOGIN_SUCCESS_DATA;
  payload: {
    data: API_GetUserDetails;
    token: string;
  };
}

export interface CleanUserDetails {
  type: ActionTypes.CLEAN_USER_DETAILS;
}

export interface LogoutUser {
  type: ActionTypes.LOGOUT;
}

/**
 * * ****************************** ACTIONS *****************************
 */

export const FC_CleanUserDetails = () => {
  return (dispatch: Dispatch) => {
    dispatch<CleanUserDetails>({
      type: ActionTypes.CLEAN_USER_DETAILS,
    });
  };
};

/**
 * @description Register the account to the api
 * @param account
 * @param MsgHandler return the error from the API
 * @returns
 */
export const FC_Login = (
  data: {
    username: string;
    password: string;
  },
  CallbackFunc: Function
) => {
  return async (dispatch: Dispatch) => {
    try {
      const res = await axios.post<API_GetUserDetails[]>(
        `${API_URL}/users/login`,
        data
      );

      console.log({ data_after_login: res.data });
      if (res.data.length > 0) {
        localStorage.setItem(APP_TOKEN_NAME, res.data[0].jwt);
        dispatch<LoginSuccessDetails>({
          type: ActionTypes.USER_LOGIN_SUCCESS_DATA,
          payload: {
            data: res.data[0],
            token: res.data[0].jwt,
          },
        });
      }
      CallbackFunc(true, "");
    } catch (error) {
      console.log("Login err: ", { ...(error as any) });
      console.log("Login err: ", error);
      // CallbackFunc(false, errorToText(error:any));
      CallbackFunc(false, errorToText(error));
    }
  };
};

/**
 * @description Check if the user is logged in based on the logged in account
 * @param account
 * @param MsgHandler return the error from the API
 * @returns
 */

export const FC_CheckLoggedIn = (callBack: (status: boolean) => void) => {
  callBack(false);
  const token = localStorage.getItem(APP_TOKEN_NAME);
  return async (dispatch: Dispatch) => {
    if (token === null) {
      dispatch<LogoutUser>({
        type: ActionTypes.LOGOUT,
      });
      return false;
    }
    try {
      setAxiosToken();
      const res = await axios.get<API_GetUserDetails[]>(
        `${API_URL}/users/current`
      );
      console.log({ logged_user_details: res.data });
      if (res.data.length > 0) {
        dispatch<LoginSuccessDetails>({
          type: ActionTypes.USER_LOGIN_SUCCESS_DATA,
          payload: {
            data: res.data[0],
            token: token!,
          },
        });
      }
      callBack(true);
    } catch (error) {
      callBack(false);
      console.log("User not: ", { ...(error as any) });
      dispatch<LogoutUser>({
        type: ActionTypes.LOGOUT,
      });
    }
  };
};

export const FC_ReloadUserInfo = (callBack: (loading: boolean) => void) => {
  const token = localStorage.getItem(APP_TOKEN_NAME);
  callBack(true);
  return async (dispatch: Dispatch) => {
    try {
      setAxiosToken();
      const res = await axios.get<API_GetUserDetails[]>(
        `${API_URL}/users/current`
      );
      console.log({ logged_user_details: res.data });
      if (res.data.length > 0) {
        dispatch<LoginSuccessDetails>({
          type: ActionTypes.USER_LOGIN_SUCCESS_DATA,
          payload: {
            data: res.data[0],
            token: token!,
          },
        });
      }
      callBack(false);
    } catch (error) {
      callBack(true);
      console.log("User not: ", { ...(error as any) });
      dispatch<LogoutUser>({
        type: ActionTypes.LOGOUT,
      });
    }
  };
};

/**
 * @description Logout the user into the system
 * @returns null
 */
export const FC_Logout = () => {
  return (dispatch: Dispatch) => {
    dispatch<LogoutUser>({
      type: ActionTypes.LOGOUT,
    });
  };
};

/**
 * @description Register the account to the api
 * @param account
 * @param MsgHandler return the error from the API
 * @returns
 */

export interface FC_ChangePassword_Interface {
  // user_id: string;
  oldPassword: string;
  newPassword: string;
}

export const FC_ChangePassword = async (
  data: FC_ChangePassword_Interface,
  callback: (
    loading: boolean,
    res: { type: "success" | "error"; msg: string } | null
  ) => void
) => {
  callback(true, null);
  try {
    setAxiosToken();

    await axios.patch(`${API_URL}/users/change/password`, {
      // user_id: data.user_id,
      oldPassword: data.oldPassword,
      newPassword: data.oldPassword,
    });

    callback(false, {
      type: "success",
      msg: "Password Updated Successfully!",
    });
  } catch (error) {
    callback(false, {
      type: "error",
      msg: errorToText(error),
    });
  }
};

/**
 * Edit users documents
 * @param data
 * @param user_id
 * @param callback
 * @returns
 */

export interface FC_ForgetPassword_Interface {
  address: string;
  verify_type: string;
}
/**
 * Send the reset password
 * @param data
 * @param callback
 * @returns
 */
export const FC_ForgetPassword = (
  data: FC_ForgetPassword_Interface,
  callback: Function
) => {
  return async (dispatch: Dispatch) => {
    try {
      setAxiosToken();

      const res = await axios.post<{
        message: string;
        code?: string;
      }>(`${API_URL}/users/password/address`, {
        address: data.address,
        verify_type: data.verify_type,
      });

      console.log({ CODE: res.data.code });

      callback(true, res.data.message);
    } catch (error) {
      callback(false, "errorToText(error:any)");
    }
  };
};

export interface FC_ForgetPassword_Check_Interface {
  address: string;
  verification_code: string;
  new_password: string;
}
/**
 * Send the new password and update that
 * @param data
 * @param callback
 * @returns
 */
export const FC_ForgetPassword_Check = (
  data: FC_ForgetPassword_Check_Interface,
  callback: Function
) => {
  return async (dispatch: Dispatch) => {
    try {
      setAxiosToken();

      const res = await axios.post<{
        message: string;
      }>(`${API_URL}/users/password/reset`, {
        address: data.address,
        verification_code: data.verification_code,
        new_password: data.new_password,
      });

      callback(true, res.data.message);
    } catch (error) {
      callback(false, "errorToText(error:any)");
    }
  };
};

export const FC_RegisterAccount = (
  data: RegisterDataInterface,
  callBack: (
    loading: boolean,
    res: { type: "success" | "error"; msg: string } | null
  ) => void
) => {
  callBack(true, null);
  return async (dispatch: Dispatch) => {
    try {
      const res = await axios.post(`${API_URL}/users/register`, data);
      console.log("Res: ", res.data);
      callBack(false, {
        type: "success",
        msg: "Registered successfully",
      });
    } catch (error: any) {
      console.log("Err: ", { ...error });
      callBack(false, {
        type: "error",
        msg: errorToText(error),
      });
    }
  };
};

export const FC_UpdatePersonalInfo = async (
  data: UpdateProfileInfoInterface,
  callBack: (
    loading: boolean,
    res: {
      type: "success" | "error";
      msg: string;
    } | null
  ) => void
) => {
  callBack(true, null);
  setAxiosToken();
  try {
    const res = await axios.patch(`${API_URL}/users/update`, data);
    console.log("Res: ", res);
    if (res) {
      callBack(false, {
        type: "success",
        msg: "Profile updated successfully!",
      });
    }
  } catch (error: any) {
    console.log("Err: ", { ...error });
    callBack(false, { type: "error", msg: errorToText(error) });
  }
};

export const FC_GetUsersListByType = (
  userType: UserType,
  callback: (
    loading: boolean,
    res: {
      type: "success" | "error";
      msg: string;
      data: UserInterface[];
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.get<UserInterface[]>(
        `${API_URL}/users/type/${userType}`
      );
      console.log({ users_by_type: res.data });
      callback(false, {
        data: res.data,
        msg: "",
        type: "success",
      });
    } catch (error: any) {
      callback(false, {
        data: [],
        msg: errorToText(error),
        type: "error",
      });
      dispatch<SetSystemMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: {
          success: "",
          error: errorToText(error),
        },
      });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_UpdateUserStatus = (
  data: {
    user_id: string;
    access_status: AccessStatusEnum;
  },
  callback: (
    loading: boolean,
    res: { type: "success" | "error"; msg: string } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      await axios.patch(`${API_URL}/users/change/access`, data);
      callback(false, {
        type: "success",
        msg: "User status updated successfully!",
      });
      dispatch<SetSystemMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: {
          success: "User status updated successfully!",
          error: "",
        },
      });
    } catch (error: any) {
      dispatch<SetSystemMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: {
          success: "",
          error: errorToText(error),
        },
      });
      callback(false, { type: "error", msg: errorToText(error) });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_UpdateUserType = (
  data: {
    user_id: string;
    user_type: UserType;
  },
  callback: (
    loading: boolean,
    res: { type: "success" | "error"; msg: string } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      await axios.patch(`${API_URL}/users/change/type`, data);
      callback(false, {
        type: "success",
        msg: "User type updated successfully!",
      });
      dispatch<SetSystemMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: {
          success: "User status updated successfully!",
          error: "",
        },
      });
    } catch (error: any) {
      dispatch<SetSystemMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: {
          success: "",
          error: errorToText(error),
        },
      });
      callback(false, { type: "error", msg: errorToText(error) });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_SubmitAccountVerification = (
  data: {
    user_id: string;
    verification_code: number;
  },
  callback: (
    loading: boolean,
    res: { type: "success" | "error"; msg: string } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      await axios.patch(`${API_URL}/users/account/verify`, data);
      callback(false, {
        type: "success",
        msg: "Account Verified Successfully!",
      });
      dispatch<SetSystemMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: {
          success: "Account Verified Successfully!",
          error: "",
        },
      });
    } catch (error: any) {
      dispatch<SetSystemMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: {
          success: "",
          error: errorToText(error),
        },
      });
      callback(false, { type: "error", msg: errorToText(error) });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_ResendAccountVerificationCode = (
  user_id: string,
  callback: (
    loading: boolean,
    res: { type: "success" | "error"; msg: string } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      await axios.get(`${API_URL}/users/resend/code/${user_id}`);
      callback(false, {
        type: "success",
        msg: "Verification Code Sent Successfully!",
      });
      dispatch<SetSystemMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: {
          success: "Verification Code Sent Successfully!",
          error: "",
        },
      });
    } catch (error: any) {
      dispatch<SetSystemMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: {
          success: "",
          error: errorToText(error),
        },
      });
      callback(false, { type: "error", msg: errorToText(error) });
      console.log("err: ", { ...error });
    }
  };
};
