import React, { Component } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoMdPersonAdd } from "react-icons/io";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import {
  Auth,
  FC_GetSystemInfo,
  FC_RegisterAccount,
  RegisterDataInterface,
  System,
} from "../../actions";
import { StoreState } from "../../reducers";
import { PersonalInfo } from "./PersonalInfo";
import { Alert } from "../Alert/Alert";

interface RegisterProps {
  auth: Auth;
  system: System;
  FC_RegisterAccount: (
    data: RegisterDataInterface,
    callBack: (
      loading: boolean,
      res: { type: "success" | "error"; msg: string } | null
    ) => void
  ) => void;
  FC_GetSystemInfo: (callback: (loading: boolean) => void) => void;
}
interface RegisterState {
  redirect: boolean;
  // ---------Form data
  user_phone: string;
  names: string;
  user_email: string;
  password: string;
  // ---------Form data
  loading: boolean;
  error: {
    target:
      | "user_phone"
      | "names"
      | "user_email"
      | "password"
      | "confirm_password"
      | "main";
    msg: string;
  } | null;
  success: string;
  passwordDisplay: boolean;
  confirm_password: string;
}

class _Register extends Component<RegisterProps, RegisterState> {
  constructor(props: RegisterProps) {
    super(props);

    this.state = {
      redirect: false,
      names: "",
      password: "",
      user_email: "",
      user_phone: "",
      loading: false,
      error: null,
      success: "",
      passwordDisplay: false,
      confirm_password: "",
    };
  }
  RegisterFn = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (this.state.names === "") {
      return this.setState({
        error: {
          target: "names",
          msg: "Please fill your names",
        },
      });
    }
    if (this.state.user_phone === "") {
      return this.setState({
        error: {
          target: "user_phone",
          msg: "Please fill your phone number",
        },
      });
    }
    if (this.state.user_email === "") {
      return this.setState({
        error: {
          target: "user_email",
          msg: "Please fill your email",
        },
      });
    }
    if (this.state.password === "") {
      return this.setState({
        error: {
          target: "password",
          msg: "Please fill password",
        },
      });
    }
    if (this.state.confirm_password === "") {
      return this.setState({
        error: {
          target: "confirm_password",
          msg: "Please confirm password",
        },
      });
    }
    if (this.state.password !== this.state.confirm_password) {
      return this.setState({
        error: {
          target: "confirm_password",
          msg: "Please password does not match",
        },
      });
    }

    this.setState({ loading: true });
    this.props.FC_RegisterAccount(
      {
        full_name: this.state.names,
        password: this.state.password,
        email: this.state.user_email,
        phone_number: this.state.user_phone,
      },
      (
        loading: boolean,
        res: { type: "success" | "error"; msg: string } | null
      ) => {
        res?.type === "error" &&
          this.setState({
            error: {
              target: "main",
              msg: res.msg,
            },
            loading: false,
            redirect: false,
            success: "",
          });
        if (res?.type === "success") {
          this.setState({
            error: null,
            loading: true,
            success: res.msg,
          });
          setTimeout(() => {
            this.setState({ redirect: true });
          }, 1000);
        }
      }
    );
  };

  render() {
    if (this.state.redirect === true) {
      return <Redirect to={"/login"} />;
    }
    return (
      <div className="bg-white w-full md:w-3/5 lg:w-4/5 rounded-lg">
        <div className="w-full p-3 px-5 md:py-8 md:px-8">
          <div className="flex flex-row items-center gap-3 ">
            <div className="text-xl font-bold">Register Account</div>
          </div>
          <div className="mt-8 text-gray-700">
            <form onSubmit={this.RegisterFn}>
              {/* Personal info */}
              <PersonalInfo
                names={this.state.names}
                user_email={this.state.user_email}
                user_phone={this.state.user_phone}
                password={this.state.password}
                confirm_password={this.state.confirm_password}
                setNames={(value) => this.setState({ names: value })}
                setEmail={(value) => this.setState({ user_email: value })}
                setPhone={(value) => this.setState({ user_phone: value })}
                setPassword={(value) => this.setState({ password: value })}
                setConfirmPassword={(value) =>
                  this.setState({ confirm_password: value })
                }
                setError={(
                  value: {
                    target:
                      | "password"
                      | "user_phone"
                      | "names"
                      | "user_email"
                      | "confirm_password";
                    msg: string;
                  } | null
                ) => this.setState({ error: value })}
                loading={false}
                error={this.state.error}
              />

              {this.state.success !== "" && (
                <div className="mt-2">
                  <Alert
                    type="success"
                    title={this.state.success}
                    onClose={() => this.setState({ error: null, success: "" })}
                  />
                </div>
              )}
              {this.state.error?.target === "main" && (
                <div className="mt-2">
                  <Alert
                    type="danger"
                    title={this.state.error.msg}
                    onClose={() => this.setState({ error: null })}
                  />
                </div>
              )}
              <div className="flex flex-row items-center justify-between gap-2 mt-6">
                <Link
                  className="text-primary-900 hover:underline text-sm font-bold"
                  to={"/login"}
                >
                  I have account, Login?
                </Link>
                <div className="flex flex-row items-center justify-end gap-3">
                  <button
                    className={`flex flex-row items-center justify-center gap-2 p-2 pr-4 bg-green-600 hover:bg-green-700 text-white rounded`}
                    type="submit"
                    disabled={this.state.loading}
                  >
                    <div>
                      {this.state.loading === true ? (
                        <AiOutlineLoading3Quarters className="text-2xl animate-spin" />
                      ) : (
                        <IoMdPersonAdd className="text-2xl" />
                      )}
                    </div>
                    <span
                      className={`${
                        this.state.loading === true
                          ? "animate__animated animate__fadeIn animate__infinite"
                          : ""
                      }`}
                    >
                      {this.state.loading === true
                        ? "Loading, please wait..."
                        : "Register"}
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  auth,
  system,
}: StoreState): { auth: Auth; system: System } => {
  return { auth, system };
};

export const Register = connect(mapStateToProps, {
  FC_RegisterAccount,
  FC_GetSystemInfo,
})(_Register);
