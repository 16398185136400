import React, { Fragment, lazy, ReactNode, Suspense } from "react";
import { connect } from "react-redux";
import {
  Auth,
  FC_CheckLoggedIn,
  FC_GetSystemInfo,
  FC_Logout,
  System,
  FC_SetSystemMessage,
  FC_SetShowNavigationStatus,
  UserType,
  AccountVerificationEnum,
} from "./actions";
import { StoreState } from "./reducers";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import LazyLoading from "./components/LazyLoading/LazyLoading";
import { Homepage } from "./containers/Homepage/Homepage";
import MainLoading from "./components/MainLoading/MainLoading";
import { NavBar } from "./components/NavBar/NavBar";
import SideNavBar from "./components/SideNavBar/SideNavBar";
import AppLoading from "./components/AppLoading/AppLoading";
import NetworkError from "./components/NetworkError/NetworkError";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import { LoginPage } from "./containers/LoginPage/LoginPage";
import { RegisterUser } from "./containers/RegisterUser/RegisterUser";
import { Alert } from "./components/Alert/Alert";
import Modal, { ModalSize, Themes } from "./components/Modal/Modal";
import { AccountVerification } from "./components/AccountVerification/AccountVerification";

export const Container = (props: {
  isAuthenticated: boolean;
  children: ReactNode;
}) => {
  return (
    <div
      className={`${
        props.isAuthenticated === true
          ? `p-1 md:p-2 h-full container mx-auto`
          : ""
      }`}
    >
      {props.children}
    </div>
  );
};

//* Components

const Dashboard = lazy(() =>
  import("./containers/Dashboard/Dashboard").then(({ Dashboard }) => ({
    default: Dashboard,
  }))
);

const Profile = lazy(() =>
  import("./containers/Profile/Profile").then(({ Profile }) => ({
    default: Profile,
  }))
);

const ChangePassword = lazy(() =>
  import("./containers/ChangePassword/ChangePassword").then(
    ({ ChangePassword }) => ({
      default: ChangePassword,
    })
  )
);

const Forum = lazy(() =>
  import("./containers/Forum/Forum").then(({ Forum }) => ({
    default: Forum,
  }))
);

const PostApproval = lazy(() =>
  import("./containers/PostApproval/PostApproval").then(({ PostApproval }) => ({
    default: PostApproval,
  }))
);

const PostsByStatuses = lazy(() =>
  import("./containers/PostsByStatuses/PostsByStatuses").then(
    ({ PostsByStatuses }) => ({
      default: PostsByStatuses,
    })
  )
);

const Users = lazy(() =>
  import("./containers/Users/Users").then(({ Users }) => ({
    default: Users,
  }))
);

const UserReferrals = lazy(() =>
  import("./containers/UserReferrals/UserReferrals").then(
    ({ UserReferrals }) => ({
      default: UserReferrals,
    })
  )
);

const SharedDocuments = lazy(() =>
  import("./containers/SharedDocuments/SharedDocuments").then(
    ({ SharedDocuments }) => ({
      default: SharedDocuments,
    })
  )
);

//* Interfaces
// props for the component
interface AppProps {
  auth: Auth;
  system: System;
  FC_CheckLoggedIn: (callBack: (status: boolean) => void) => void;
  FC_Logout: () => void;
  FC_GetSystemInfo: (callback: (loading: boolean) => void) => void;
  FC_SetSystemMessage: (success: string, error: string) => void;
  FC_SetShowNavigationStatus: (status: boolean) => void;
}

interface AppState {
  loading: boolean;
  showSideNav: boolean;
}

class _App extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);

    this.state = {
      loading: false,
      showSideNav: false,
    };
  }

  componentDidMount() {
    //* check if the user is logged in
    this.props.FC_CheckLoggedIn((status: boolean) => {
      if (status === true) {
        this.setState({ loading: false });
      }
    });
    // if (this.props.system.basic_info === null) {
    //   this.setState({ loading: true });
    //   this.props.FC_GetSystemInfo((loading: boolean) =>
    //     this.setState({ loading: loading })
    //   );
    // }
    if (this.props.auth.user?.user_type !== UserType.BENEFICIARY) {
      this.setState({ showSideNav: true });
    }
  }

  render() {
    // constants
    const authenticationPath = "/login";

    if (this.props.auth.loading === true) {
      return <MainLoading />;
    }

    if (this.state.loading === true) {
      return <AppLoading />;
    }
    return (
      <Fragment>
        <Router>
          <div className="h-screen">
            {this.props.system.message.error !== "" && (
              <div className="fixed right-3 top-3 z-50 animate__animated animate__zoomInUp animate__fast">
                <Alert
                  type="danger"
                  title={"Error occurred!"}
                  description={this.props.system.message.error}
                  onClose={() => this.props.FC_SetSystemMessage("", "")}
                  allow_time_out={true}
                />
              </div>
            )}
            {this.props.system.message.success !== "" && (
              <div className="fixed right-3 top-3 z-50 animate__animated animate__zoomInUp animate__fast">
                <Alert
                  type="success"
                  title={"Success"}
                  description={this.props.system.message.success}
                  onClose={() => this.props.FC_SetSystemMessage("", "")}
                  allow_time_out={true}
                />
              </div>
            )}
            {/* Check connectivity */}
            {navigator.onLine === false && <NetworkError />}
            {this.props.auth.isAuthenticated === true &&
              this.props.auth.user?.user_type !== UserType.BENEFICIARY && (
                <NavBar
                  auth={this.props.auth}
                  FC_Logout={this.props.FC_Logout}
                  showSideNav={this.state.showSideNav}
                  setShowSideNav={(showSideNav: boolean) =>
                    this.setState({ showSideNav: showSideNav })
                  }
                  CheckIfDoingExam={!this.props.system.showNavigation}
                />
              )}
            <div
              className={`${
                this.props.auth.isAuthenticated === true
                  ? `bg-gray-200 h-full ${
                      this.state.showSideNav === true &&
                      this.props.auth.user?.user_type !== UserType.BENEFICIARY
                        ? "lg:pl-64"
                        : ""
                    } ${
                      this.props.auth.user?.user_type === UserType.BENEFICIARY
                        ? ""
                        : "pt-14"
                    } overflow-y-auto`
                  : ""
              }`}
              style={{ transition: "0.7s" }}
            >
              {this.props.auth.isAuthenticated === true &&
                this.state.showSideNav === true &&
                this.props.auth.user?.user_type !== UserType.BENEFICIARY && (
                  <SideNavBar
                    auth={this.props.auth}
                    showSideNav={this.props.system.showNavigation}
                    setShowSideNav={(showSideNav: boolean) =>
                      this.setState({ showSideNav: showSideNav })
                    }
                  />
                )}
              <div>
                <Switch>
                  <Route exact path="/" component={Homepage} />
                  <Route exact path="/tenders" component={Homepage} />
                  <Route exact path="/login" component={LoginPage} />
                  <Route exact path="/register" component={RegisterUser} />
                  <Suspense fallback={<LazyLoading />}>
                    <ProtectedRoute
                      path="/forum"
                      component={Forum}
                      isAuthenticated={this.props.auth.isAuthenticated}
                      authenticationPath={authenticationPath}
                      loading={this.state.loading}
                      exact
                    />
                    <Container
                      isAuthenticated={this.props.auth.isAuthenticated}
                    >
                      <ProtectedRoute
                        path="/dashboard"
                        component={Dashboard}
                        isAuthenticated={this.props.auth.isAuthenticated}
                        authenticationPath={authenticationPath}
                        loading={this.state.loading}
                        exact
                      />
                      <ProtectedRoute
                        path="/change-password"
                        component={ChangePassword}
                        isAuthenticated={this.props.auth.isAuthenticated}
                        authenticationPath={authenticationPath}
                        loading={this.state.loading}
                        exact
                      />
                      <ProtectedRoute
                        path="/profile"
                        component={Profile}
                        isAuthenticated={this.props.auth.isAuthenticated}
                        authenticationPath={authenticationPath}
                        loading={this.state.loading}
                        exact
                      />
                      <ProtectedRoute
                        path="/post-approval"
                        component={PostApproval}
                        isAuthenticated={this.props.auth.isAuthenticated}
                        authenticationPath={authenticationPath}
                        loading={this.state.loading}
                        exact
                      />
                      <ProtectedRoute
                        path="/posts-by-statuses"
                        component={PostsByStatuses}
                        isAuthenticated={this.props.auth.isAuthenticated}
                        authenticationPath={authenticationPath}
                        loading={this.state.loading}
                        exact
                      />
                      <ProtectedRoute
                        path="/users"
                        component={Users}
                        isAuthenticated={this.props.auth.isAuthenticated}
                        authenticationPath={authenticationPath}
                        loading={this.state.loading}
                        exact
                      />
                      <ProtectedRoute
                        path="/user-referrals"
                        component={UserReferrals}
                        isAuthenticated={this.props.auth.isAuthenticated}
                        authenticationPath={authenticationPath}
                        loading={this.state.loading}
                        exact
                      />
                      <ProtectedRoute
                        path="/shared-documents"
                        component={SharedDocuments}
                        isAuthenticated={this.props.auth.isAuthenticated}
                        authenticationPath={authenticationPath}
                        loading={this.state.loading}
                        exact
                      />
                    </Container>
                  </Suspense>
                </Switch>
              </div>
            </div>
          </div>
        </Router>
        {this.props.auth.user?.verification ===
          AccountVerificationEnum.NOT_VERIFIED && (
          <Modal
            backDrop={true}
            theme={Themes.default}
            close={() => {}}
            backDropClose={false}
            widthSizeClass={ModalSize.medium}
            displayClose={false}
            padding={{
              title: undefined,
              body: undefined,
              footer: undefined,
            }}
          >
            <AccountVerification />
          </Modal>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  auth,
  system,
}: StoreState): {
  auth: Auth;
  system: System;
} => {
  return {
    auth,
    system,
  };
};

export const App = connect(mapStateToProps, {
  FC_CheckLoggedIn,
  FC_Logout,
  FC_GetSystemInfo,
  FC_SetSystemMessage,
  FC_SetShowNavigationStatus,
})(_App);
