import React from "react";
import UR_ICON from "../../assets/logo.jpeg";

const AppLoading = () => {
  return (
    <div>
      <div className="w-full h-screen pt-20 flex flex-col items-center justify-center gap-5">
        <div>
          <img
            className="h-36 w-36 mb-5 animate-pulse"
            src={UR_ICON}
            alt="Valuation Management System"
          />
        </div>
        <div className="text-xl font-extrabold text-black animate__animated animate__fadeIn animate__infinite">
          Loading, please wait...
        </div>
      </div>
    </div>
  );
};

export default AppLoading;
