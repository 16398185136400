import React, { Component, Fragment } from "react";
import { StoreState } from "../../reducers";
import { Auth, FC_GetSystemInfo, FC_Login, System } from "../../actions";
import { connect } from "react-redux";
import Container from "../../components/Container/Container";
import PublicNav from "../../components/PublicNav/PublicNav";
import { Link } from "react-router-dom";
import { BsArrowRightCircle, BsCheckCircle } from "react-icons/bs";
import { FaChalkboardTeacher } from "react-icons/fa";
import HomeImage from "../../assets/HomeSM.png";
import { HiUserGroup } from "react-icons/hi";

interface AppProps {
  auth: Auth;
  system: System;
}

interface AppState {
  loading: boolean;
}

class _App extends Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  componentDidMount = () => {};
  render() {
    return (
      <Fragment>
        <div>
          {this.props.auth.isAuthenticated === false && (
            <div>
              <PublicNav blue={true} />
              <div className="mt-10"></div>
            </div>
          )}
          <div className="min-h-screen">
            <Container
              className={`bg-primary-900 bg-home-pattern bg-no-repeat bg-cover`}
            >
              <div className="grid grid-cols-12 gap-3 w-full py-8 md:py-28">
                <div className="col-span-12">
                  <img
                    src={HomeImage}
                    className="md:hidden w-full"
                    alt="WIDUIT"
                  />
                </div>
                <div className="col-span-12 lg:col-span-7 flex flex-col justify-center bg-primary-100 p-8 rounded-r-xl border-l-8 border-yellow-600 shadow-2l animate__animated animate__bounceIn">
                  <div className="font-extrabold text-2xl uppercase md:text-3xl text-black">
                    Widuit Platform
                  </div>
                  <div className="mt-6 text-gray-600 font-light">
                    This platform is utilised to support the administrative
                    efficiency of a community-led and owned project for females
                    using or injecting drugs that provide, comprehensive and
                    supportive sexual and reproductive health and rights (SRHR)
                    care, information and services, including addressing
                    gender-based violence (GBV). It enable exchanges and sharing
                    of information among service providers and project staff,
                    support requests from females facing Gender Based Violence
                    (GBV), information provision, and referrals for a network of
                    target beneficiaries and community members.
                  </div>
                  <div className="mt-4">
                    <Link
                      to="/login"
                      className="bg-primary-750 hover:bg-primary-800 rounded-md px-4 py-2 font-light text-white flex flex-row items-center justify-center gap-3 w-max animate__animated animate__fadeIn animate__slower"
                    >
                      <div>Get Started</div>
                      <div>
                        <BsArrowRightCircle className="text-xl" />
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-span-12 lg:col-span-5"></div>
              </div>
            </Container>
            <Container className={`bg-primary-100 py-10`}>
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12 text-black font-extrabold text-3xl">
                  How it works
                </div>
                <div className="col-span-12 lg:col-span-6 py-6 pt-0 h-full">
                  <div className="w-full rounded-lg bg-white p-6 h-full flex flex-col md:flex-row gap-2 md:gap-4 animate__animated animate__fadeIn animate__slower">
                    <div className="pt-2 pl-2">
                      <div className="flex items-center justify-center h-20 w-20 bg-white border-2 border-primary-700 text-primary-800 rounded-full">
                        <HiUserGroup className="text-5xl" />
                      </div>
                    </div>
                    <div>
                      <div className="text-black font-bold text-2xl mb-1">
                        For Beneficiaries
                      </div>
                      <div className="font-light text-sm">
                        Are you a female using drugs or are you affected by GBV?
                        : Receive timely, comprehensive and supportive sexual
                        and reproductive health and rights (SRHR) care,
                        information and services. You can seek advice, request
                        information or ask a question.
                      </div>
                      <div className="mt-4">
                        <Link
                          to="/register"
                          className="bg-primary-750 text-white hover:bg-primary-800 rounded-full px-5 pr-2 py-2 font-semibold text-base uppercase flex flex-row items-center justify-center gap-3 w-max"
                        >
                          <div>Register now</div>
                          <div>
                            <BsArrowRightCircle className="text-xl" />
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-span-12 lg:col-span-6 py-6 pt-0 h-full">
                  <div className="w-full rounded-lg p-6 h-full bg-white animate__animated animate__fadeIn animate__slower">
                    <div className="flex flex-col md:flex-row gap-2 md:gap-4">
                      <div className="pt-2 pl-1">
                        <div className="flex items-center justify-center h-20 w-20 bg-white text-yellow-600 border-2 border-yellow-600 rounded-full">
                          <FaChalkboardTeacher className="text-5xl" />
                        </div>
                      </div>
                      <div className="text-sm">
                        <div className="text-black font-extrabold text-2xl mb-1">
                          For Service Providers
                        </div>
                        <div className="font-light">
                          Are you a doctor, pharmacist, nurse psychologist,
                          social work or community health worker?: Share timely,
                          comprehensive and supportive sexual and reproductive
                          health and rights (SRHR) care, information and
                          services. As service provider you can:
                        </div>
                      </div>
                    </div>

                    <div className="font-semilight text-gray-500 text-sm">
                      <ul className="mt-3 text-sm flex flex-col gap-2 text-black">
                        <li className="flex flex-row items-center gap-2">
                          <div>
                            <BsCheckCircle className="text-xl text-yellow-600" />
                          </div>
                          <div>Offer accurate information</div>
                        </li>
                        <li className="flex flex-row items-center gap-2">
                          <div>
                            <BsCheckCircle className="text-xl text-yellow-600" />
                          </div>
                          <div>Respond to questions</div>
                        </li>
                        <li className="flex flex-row items-center gap-2">
                          <div>
                            <BsCheckCircle className="text-xl text-yellow-600" />
                          </div>
                          <div>Exchanges and share information</div>
                        </li>
                        <li className="flex flex-row items-center gap-2">
                          <div>
                            <BsCheckCircle className="text-xl text-yellow-600" />
                          </div>
                          <div>
                            Support requests from females facing Gender Based
                            Violence (GBV)
                          </div>
                        </li>
                        <li className="flex flex-row items-center gap-2">
                          <div>
                            <BsCheckCircle className="text-xl text-yellow-600" />
                          </div>
                          <div>
                            Referral service of beneficiaries and community
                            members
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
            <Container className={`bg-white py-10`}>
              <div className="px-2 md:px-0">
                <div className="text-3xl font-extrabold text-black mb-4">
                  Who is WIDUIT?
                </div>
                <div className="font-light">
                  The Women Injecting Drug Users Initiative Tanzania (WIDUIT) is
                  a peer and women led organisation that aims to promote health
                  and defend the rights of women who use and inject drugs in
                  Tanzania. It was founded in Tanzania by a combination of
                  efforts of former Women Drug Users, Former Drug Users, Medical
                  and Social Professionals who wanted to put their experience
                  and capacity together to support vulnerable and disadvantaged
                  women who use drugs
                </div>
              </div>
            </Container>
            <Container className={`bg-gray-700 py-10`}>
              <div className="text-gray-300 grid grid-cols-12 gap-3">
                <div className="col-span-12 md:col-span-6">
                  <div className="font-bold">
                    Women Injecting Drug Users Initiative Tanzania (WIDUIT)
                  </div>
                  <div className="text-yellow-400 text-sm font-light">
                    Dares Salaam, Tanzania
                  </div>
                </div>
                <div className="col-span-12 md:col-span-6 lg:col-span-3"></div>
                <div className="col-span-12 md:col-span-6 lg:col-span-3">
                  <div className="font-bold text-lg text-white">
                    Contact Support
                  </div>
                  <div className="text-sm flex flex-row items-center gap-2 font-light">
                    <span>Email:</span>
                    <a
                      href="mailto:officewiduitexecutivedirector@gmail.com"
                      title="officewiduitexecutivedirector@gmail.com"
                      className="text-white hover:underline"
                    >
                      officewiduitexecutivedirector@gmail.com
                    </a>
                  </div>
                </div>
              </div>
            </Container>
            <Container
              className={`bg-gray-800 py-6 text-gray-400 font-light text-sm`}
            >
              <div className="flex md:flex-row items-center justify-between gap-3">
                <div className="">
                  WIDUITPLATFORM Version1.0, {2022} Copyright © WIDUIT
                </div>
                {/* <div>
                  <Link to={""} target="_blank">
                    Developed under UR Binary Hub
                  </Link>
                </div> */}
              </div>
            </Container>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  auth,
  system,
}: StoreState): { auth: Auth; system: System } => {
  return { auth, system };
};

export const Homepage = connect(mapStateToProps, {
  FC_Login,
  FC_GetSystemInfo,
})(_App);
